import React from 'react';
import { useDispatch } from 'react-redux';

const BriefScreen = () => {
  const dispatch = useDispatch();
  return (
    <div>

    </div>
  );
};

export default BriefScreen;
